import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: '/JewrQXYp',
    },
    // {
    //   path: '/:slug',
    //   component: () => import('@/views/donate/DonateIndex.vue'),
    // },
    // {
    //   path: '/v2/:slug',
    //   component: () => import('@/views/donate-v2/DonateIndex.vue'),
    // },
    // 預約
    {
      path: '',
      component: () => import('@/views/reserve/ReserveIndex.vue'),
      meta: { layout: 'full', resource: 'Auth', filter: true },
      children: [
        {
          // path: '/liff/:botId/reserve',
          path: '/:slug',
          name: 'apps-reserve-page',
          component: () => import('@/views/reserve/reserve-list/ReserveList.vue'),
          meta: { layout: 'full', resource: 'Auth', filter: true },
        },
        {
          path: '/liff/:slug/reserve/:id',
          name: 'reserve-edit-page',
          component: () => import('@/views/reserve/reserve-submit/ReserveSubmit.vue'),
          meta: { layout: 'full', resource: 'Auth', filter: true },
        },
      ],
    },
    // 預約訂單
    {
      path: '',
      component: () => import('@/views/reserve-order/ReserveOrderIndex.vue'),
      meta: { layout: 'full', resource: 'Auth', filter: true },
      children: [
        {
          path: '/liff/:slug/reserve-order',
          name: 'reserve-order-page',
          component: () => import('@/views/reserve-order/ReserveOrder.vue'),
          meta: { layout: 'full', resource: 'Auth', filter: true },
        },
      ],
    },
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
